import React from "react";
import Meta from "./../components/Meta";
import NavbarCustom4 from "./../components/NavbarCustom4";
import NavbarCustom5 from "./../components/NavbarCustom5";
import NavbarCustom6 from "./../components/NavbarCustom6";

function NavbarLandingPage(props) {
  return (
    <>
      <Meta title="Navbar Landing" />
      {/* <NavbarCustom4
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      /> */}
      <NavbarCustom5
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      />
      {/* <NavbarCustom6
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      /> */}
    </>
  );
}

export default NavbarLandingPage;
