import React from "react";
import Meta from "./../components/Meta";
import CarouselSection from "./../components/CarouselSection";
import VideoSection from "./../components/VideoSection";
import AppleMusicSection from "./../components/AppleMusicSection";

function MediaPage(props) {
  return (
    <>
      <Meta title="Media" />
      <CarouselSection
        items={[
          {
            image:
              "https://images.unsplash.com/photo-1568198473832-b6b0f46328c1?auto=format&fit=crop&w=1200&h=600&q=80",
            caption: "Caption for the first image",
          },
          {
            image:
              "https://images.unsplash.com/photo-1511040039597-7de17655e9b3?auto=format&fit=crop&w=1200&height=600&q=80",
            caption: "Caption for the second image",
          },
          {
            image:
              "https://images.unsplash.com/photo-1658507165837-8d9abd489ec9?auto=format&fit=crop&w=1200&h=600&q=80",
            caption: "Caption for the third image",
          },
        ]}
      />
      <VideoSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Your title here"
        subtitle=""
        embedUrl="https://www.youtube.com/embed/z9Ul9ccDOqE"
      />
      <AppleMusicSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Featured Song"
        subtitle=""
      />
    </>
  );
}

export default MediaPage;
