import React from "react";
import Meta from "./../components/Meta";
import NavbarCustom from "./../components/NavbarCustom";
import NavbarCustom2 from "./../components/NavbarCustom2";
import NavbarCustom3 from "./../components/NavbarCustom3";

function NavbarPage(props) {
  return (
    <>
      <Meta title="Navbar" />
      {/* <NavbarCustom
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      /> */}
      <NavbarCustom2
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      />
      {/* <NavbarCustom3
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      /> */}
    </>
  );
}

export default NavbarPage;
