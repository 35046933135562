import React from "react";

function VerificationForm(props) {
    return (
        <>
            <body style={{ cursor: 'auto', WebkitFontSmoothing: 'antialiased', textSizeAdjust: '100%', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', margin: '0px', boxSizing: 'border-box' }}>
                <div style={{ boxSizing: 'border-box' }}>
                    <div data-ux="Page" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', width: '100%', backgroundColor: 'rgb(255, 255, 255)', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                        <div data-ux="Block" style={{ fontSize: '18px', backgroundColor: 'rgb(255, 255, 255)', minHeight: '715.2px', fontFamily: 'Lusitana, Georgia, serif', marginLeft: 'auto', marginBottom: 'auto', marginRight: 'auto', marginTop: 'auto', overflow: 'hidden', position: 'relative', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                            <div style={{ boxSizing: 'border-box' }}>
                                <div data-ux="Header" role="main" data-aid="HEADER_WIDGET" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgb(255, 255, 255)', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                    <div style={{ boxSizing: 'border-box' }}>
                                        <div style={{ boxSizing: 'border-box' }}></div>
                                        <section data-ux="Section" data-aid="HEADER_SECTION" style={{ fontSize: '18px', padding: '0px', paddingBottom: '0px', paddingTop: '0px', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgb(255, 255, 255)', textTransform: 'none', letterSpacing: 'normal', display: 'block', boxSizing: 'border-box' }}>
                                            <div data-ux="Block" style={{ fontSize: '18px', marginBottom: '0px', position: 'relative', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                <div data-ux="Block" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                    <div data-ux="ContainerFluid" style={{ fontSize: '18px', maxWidth: '100%', paddingRight: '24px', paddingLeft: '24px', marginRight: 'auto', marginLeft: 'auto', position: 'relative', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                        <div data-ux="Block" style={{ fontSize: '18px', display: 'block', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                            <nav data-ux="Container" style={{ width: '1280px', fontSize: '18px', paddingBottom: '32px', paddingTop: '32px', maxWidth: '100%', paddingRight: '24px', paddingLeft: '24px', marginRight: 'auto', marginLeft: 'auto', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', display: 'block', boxSizing: 'border-box' }}>
                                                                <div data-ux="Block" style={{ fontSize: '18px', paddingTop: '24px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flex: '1 1 0%', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                    <div data-ux="Block" style={{ fontSize: '18px', width: '30%', justifyContent: 'flex-start', display: 'flex', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}></div>
                                                                    <div data-ux="Block" style={{ fontSize: '18px', width: '40%', textAlign: 'center', justifyContent: 'center', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                        <div data-ux="Block" data-aid="HEADER_LOGO_RENDERED" style={{ fontSize: '18px', maxWidth: 'calc(100% - 0.1px)', fontFamily: 'Lato, arial, sans-serif', display: 'inline-block', letterSpacing: '1px', boxSizing: 'border-box' }}><a rel="" role="link" aria-haspopup="menu" data-ux="Link" data-page="bf369245-c1c9-48ef-b6e5-a0e23ee15c21" title="DigiQ-AI" href="/" data-typography="LinkAlpha" data-tccl="ux2.HEADER.header9.Logo.Default.Link.Default.266557.click,click" style={{ fontSize: '18px', fontWeight: 400, color: 'rgb(28, 125, 127)', width: 'auto', borderLeft: '0px none rgb(28, 125, 127)', borderBottom: '0px none rgb(28, 125, 127)', borderRight: '0px none rgb(28, 125, 127)', borderTop: '0px none rgb(28, 125, 127)', cursor: 'pointer', display: 'inline', overflowWrap: 'break-word', textDecoration: 'none solid rgb(28, 125, 127)', textTransform: 'none', letterSpacing: '1px', maxWidth: '100%', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box' }}>
                                                                            <div data-ux="Block" style={{ fontSize: '18px', display: 'inline-block', paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px', paddingTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><img src="//img1.wsimg.com/isteam/ip/db3cb174-087a-428e-9ece-f527aaf3cb12/DigiQ-Logo.png/:/rs=h:126,cg:true,m/qt=q:100/ll" srcSet="//img1.wsimg.com/isteam/ip/db3cb174-087a-428e-9ece-f527aaf3cb12/DigiQ-Logo.png/:/rs=w:405,h:126,cg:true,m/cr=w:405,h:126/qt=q:100/ll, //img1.wsimg.com/isteam/ip/db3cb174-087a-428e-9ece-f527aaf3cb12/DigiQ-Logo.png/:/rs=w:466,h:145,cg:true,m/cr=w:466,h:145/qt=q:100/ll 2x" alt="DigiQ-AI" data-ux="ImageLogo" data-aid="HEADER_LOGO_IMAGE_RENDERED" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgb(255, 255, 255)', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }} /></div>
                                                                        </a></div>
                                                                    </div>
                                                                    <div data-ux="Block" style={{ fontSize: '18px', justifyContent: 'flex-end', width: '30%', alignItems: 'center', display: 'flex', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}></div>
                                                                </div>
                                                                <div data-ux="Block" style={{ fontSize: '18px', paddingBottom: '24px', paddingTop: '24px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flex: '1 1 0%', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                    <div data-ux="Block" style={{ fontSize: '18px', justifyContent: 'center', display: 'flex', flex: '1 1 0%', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}></div>
                                                                </div>
                                                            </nav>
                                                        </div>
                                                        <div data-ux="Block" style={{ fontSize: '18px', display: 'none', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                            <nav data-ux="Block" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', width: '100%', textTransform: 'none', letterSpacing: 'normal', display: 'block', boxSizing: 'border-box' }}>
                                                                <div data-ux="Block" style={{ fontSize: '18px', paddingBottom: '24px', paddingTop: '24px', alignItems: 'center', justifyContent: 'space-between', display: 'flex', fontFamily: 'Lusitana, Georgia, serif', width: '100%', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                    <div data-ux="Block" style={{ fontSize: '18px', width: '0px', paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px', paddingTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}></div>
                                                                    <div data-ux="Block" style={{ fontSize: '18px', textAlign: 'center', display: 'flex', fontFamily: 'Lusitana, Georgia, serif', width: '100%', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                        <div data-ux="Block" data-aid="HEADER_LOGO_RENDERED" style={{ fontSize: '18px', maxWidth: 'calc(100% - 0.1px)', fontFamily: 'Lato, arial, sans-serif', display: 'block', letterSpacing: '1px', boxSizing: 'border-box' }}><a rel="" role="link" aria-haspopup="menu" data-ux="Link" data-page="bf369245-c1c9-48ef-b6e5-a0e23ee15c21" title="DigiQ-AI" href="/" data-typography="LinkAlpha" data-tccl="ux2.HEADER.header9.Logo.Default.Link.Default.266558.click,click" style={{ fontSize: '18px', fontWeight: 400, color: 'rgb(28, 125, 127)', width: 'auto', borderLeft: '0px none rgb(28, 125, 127)', borderBottom: '0px none rgb(28, 125, 127)', borderRight: '0px none rgb(28, 125, 127)', borderTop: '0px none rgb(28, 125, 127)', cursor: 'pointer', display: 'inline', overflowWrap: 'break-word', textDecoration: 'none solid rgb(28, 125, 127)', textTransform: 'none', letterSpacing: '1px', maxWidth: '100%', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box' }}>
                                                                            <div data-ux="Block" style={{ fontSize: '18px', display: 'inline-block', paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px', paddingTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><img src="//img1.wsimg.com/isteam/ip/db3cb174-087a-428e-9ece-f527aaf3cb12/DigiQ-Logo.png/:/rs=h:104,cg:true,m/qt=q:100/ll" srcSet="//img1.wsimg.com/isteam/ip/db3cb174-087a-428e-9ece-f527aaf3cb12/DigiQ-Logo.png/:/rs=w:334,h:104,cg:true,m/cr=w:334,h:104/qt=q:100/ll, //img1.wsimg.com/isteam/ip/db3cb174-087a-428e-9ece-f527aaf3cb12/DigiQ-Logo.png/:/rs=w:466,h:145,cg:true,m/cr=w:466,h:145/qt=q:100/ll 2x" alt="DigiQ-AI" data-ux="ImageLogo" data-aid="HEADER_LOGO_IMAGE_RENDERED" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgb(255, 255, 255)', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }} /></div>
                                                                        </a></div>
                                                                    </div>
                                                                    <div data-ux="Block" style={{ fontSize: '18px', width: '0px', paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px', paddingTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}></div>
                                                                </div>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div style={{ boxSizing: 'border-box' }}>
                                <div data-ux="Widget" role="region" style={{ boxSizing: 'border-box' }}>
                                    <div style={{ boxSizing: 'border-box' }}>
                                        <section data-ux="Section" style={{ fontSize: '18px', position: 'relative', paddingBottom: '40px', paddingTop: '40px', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgb(255, 255, 255)', textTransform: 'none', letterSpacing: 'normal', display: 'block', boxSizing: 'border-box' }}>
                                            <div data-ux="Container" style={{ width: '1280px', fontSize: '18px', maxWidth: '100%', paddingRight: '24px', paddingLeft: '24px', marginRight: 'auto', marginLeft: 'auto', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                <h1 role="heading" aria-level="1" data-ux="SectionHeading" data-aid="CONTACT_SECTION_TITLE_REND" data-promoted-from="2" data-order="0" data-typography="HeadingBeta" style={{ fontSize: '18px', marginBottom: '32px', fontWeight: 400, color: 'rgb(38, 131, 133)', textAlign: 'left', marginRight: '0px', marginLeft: '0px', lineHeight: '25.2px', textTransform: 'uppercase', letterSpacing: '2.25px', marginTop: '0px', overflowWrap: 'break-word', fontFamily: 'Lusitana, Georgia, serif', boxSizing: 'border-box' }}><span data-ux="Element" style={{ boxSizing: 'border-box' }}>SEND YOUR FRAUD PREVENTION REQUEST</span></h1>
                                                <div data-ux="Content" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                    <div data-ux="Element" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><span data-ux="Element" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                        <div data-ux="Grid" style={{ fontSize: '18px', marginLeft: '-24px', marginBottom: '0px', marginRight: '-24px', marginTop: '0px', flexWrap: 'wrap', flexDirection: 'row', boxSizing: 'border-box', display: 'flex', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal' }}>
                                                            <div data-ux="GridCell" style={{ fontSize: '18px', maxWidth: '66.6667%', flexBasis: '66.6667%', marginLeft: '213.325px', paddingLeft: '24px', paddingBottom: '0px', paddingRight: '24px', paddingTop: '0px', flexShrink: 1, flexGrow: 1, boxSizing: 'border-box', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal' }}>
                                                                <div data-ux="Block" data-aid="CONTACT_FORM_CONTAINER_REND" style={{ fontSize: '18px', textAlign: 'center', justifyContent: 'center', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                    <form aria-live="polite" data-ux="Form" style={{ fontSize: '18px', marginBottom: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><input type="text" name="_app_id" autoComplete="off" tabIndex="-1" value="" style={{ display: 'none', boxSizing: 'border-box' }} />
                                                                        <h4 role="heading" aria-level="4" data-ux="HeadingMinor" data-aid="CONTACT_FORM_TITLE_REND" data-typography="HeadingDelta" style={{ fontSize: '24px', fontWeight: 400, color: 'rgb(27, 27, 27)', marginBottom: '24px', marginRight: '0px', marginLeft: '0px', lineHeight: '30px', overflowWrap: 'break-word', letterSpacing: '1.08px', marginTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', boxSizing: 'border-box' }}>Enter the Access Code for your account</h4>
                                                                        <div data-ux="Block" style={{ fontSize: '18px', paddingBottom: '24px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                            <div data-ux="Block" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                                <div data-ux="InputFloatLabel" type="text" data-aid="Access Code" style={{ fontSize: '18px', backgroundColor: 'rgba(0, 0, 0, 0)', position: 'relative', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><input role="textbox" aria-multiline="false" type="text" data-ux="InputFloatLabel" data-aid="Access Code" data-typography="BodyAlpha" value="" style={{ backgroundImage: 'url(&quot', data: 'image/png', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundSize: '16px 18px', backgroundPosition: '98% 50%', fontSize: '18px', color: 'rgb(94, 94, 94)', borderStyle: 'solid', borderRadius: '0px', paddingRight: '8px', paddingLeft: '8px', paddingBottom: '8px', paddingTop: '23px', borderLeftWidth: '0px', borderBottomWidth: '0.8px', borderRightWidth: '0px', borderTopWidth: '0px', width: '100%', borderColor: 'rgb(226, 226, 226)', backgroundColor: 'rgba(0, 0, 0, 0)', fontWeight: 400, fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', padding: '23px 8px 8px', verticalAlign: 'middle', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal', margin: '0px', boxSizing: 'border-box' }} /><label data-ux="InputFloatLabelLabel" htmlFor="input1" data-typography="BodyAlpha" style={{ fontSize: '18px', pointerEvents: 'none', transition: 'all 0.15s ease 0s', left: '8px', top: '16.7375px', position: 'absolute', display: 'block', color: 'rgb(94, 94, 94)', fontWeight: 400, overflowWrap: 'break-word', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', verticalAlign: 'middle', boxSizing: 'border-box' }}>Access Code*</label></div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-ux="Block" style={{ fontSize: '18px', paddingBottom: '24px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                            <div data-ux="Block" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                                <div data-ux="InputFloatLabel" type="text" data-aid="Email to verify" style={{ fontSize: '18px', backgroundColor: 'rgba(0, 0, 0, 0)', position: 'relative', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><input role="textbox" aria-multiline="false" type="text" data-ux="InputFloatLabel" data-aid="Email to verify" data-typography="BodyAlpha" value="" style={{ fontSize: '18px', color: 'rgb(94, 94, 94)', borderStyle: 'solid', borderRadius: '0px', paddingRight: '8px', paddingLeft: '8px', paddingBottom: '8px', paddingTop: '23px', borderLeftWidth: '0px', borderBottomWidth: '0.8px', borderRightWidth: '0px', borderTopWidth: '0px', width: '100%', borderColor: 'rgb(226, 226, 226)', backgroundColor: 'rgba(0, 0, 0, 0)', fontWeight: 400, fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', padding: '23px 8px 8px', verticalAlign: 'middle', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal', margin: '0px', boxSizing: 'border-box' }} /><label data-ux="InputFloatLabelLabel" htmlFor="input2" data-typography="BodyAlpha" style={{ fontSize: '18px', pointerEvents: 'none', transition: 'all 0.15s ease 0s', left: '8px', top: '16.7375px', position: 'absolute', display: 'block', color: 'rgb(94, 94, 94)', fontWeight: 400, overflowWrap: 'break-word', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', verticalAlign: 'middle', boxSizing: 'border-box' }}>Email to verify*</label></div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-ux="Block" style={{ fontSize: '18px', paddingBottom: '24px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                            <div data-ux="Block" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                                <div data-ux="InputFloatLabel" type="text" data-aid="Phone to verify" style={{ fontSize: '18px', backgroundColor: 'rgba(0, 0, 0, 0)', position: 'relative', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><input role="textbox" aria-multiline="false" type="text" data-ux="InputFloatLabel" data-aid="Phone to verify" data-typography="BodyAlpha" value="" style={{ fontSize: '18px', color: 'rgb(94, 94, 94)', borderStyle: 'solid', borderRadius: '0px', paddingRight: '8px', paddingLeft: '8px', paddingBottom: '8px', paddingTop: '23px', borderLeftWidth: '0px', borderBottomWidth: '0.8px', borderRightWidth: '0px', borderTopWidth: '0px', width: '100%', borderColor: 'rgb(226, 226, 226)', backgroundColor: 'rgba(0, 0, 0, 0)', fontWeight: 400, fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', padding: '23px 8px 8px', verticalAlign: 'middle', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal', margin: '0px', boxSizing: 'border-box' }} /><label data-ux="InputFloatLabelLabel" htmlFor="input3" data-typography="BodyAlpha" style={{ fontSize: '18px', pointerEvents: 'none', transition: 'all 0.15s ease 0s', left: '8px', top: '16.7375px', position: 'absolute', display: 'block', color: 'rgb(94, 94, 94)', fontWeight: 400, overflowWrap: 'break-word', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', verticalAlign: 'middle', boxSizing: 'border-box' }}>Phone to verify</label></div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-ux="Block" style={{ fontSize: '18px', paddingBottom: '24px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                            <div data-ux="Block" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><textarea role="textbox" aria-multiline="true" rows="4" aria-label="Other notes" data-ux="InputTextArea" placeholder="Other notes" data-aid="Other notes" data-typography="BodyAlpha" style={{ fontSize: '18px', appearance: 'none', resize: 'vertical', borderRadius: '3px', paddingBottom: '16px', paddingTop: '16px', borderLeftWidth: '0.8px', borderRightWidth: '0.8px', borderTopWidth: '0.8px', color: 'rgb(94, 94, 94)', borderStyle: 'solid', paddingRight: '8px', paddingLeft: '8px', borderBottomWidth: '0.8px', width: '100%', borderColor: 'rgb(226, 226, 226)', backgroundColor: 'rgba(0, 0, 0, 0)', fontWeight: 400, fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', lineHeight: '31.5px', padding: '16px 8px', marginTop: '0px', marginBottom: '0px', overflow: 'auto', border: '0.8px solid rgb(226, 226, 226)', margin: '0px', boxSizing: 'border-box' }}></textarea></div>
                                                                        </div>
                                                                        <div data-ux="Block" style={{ fontSize: '18px', paddingBottom: '24px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                            <div data-ux="Block" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                                <div data-ux="Block" style={{ fontSize: '18px', textAlign: 'center', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><button data-ux-btn="primary" data-ux="ButtonPrimary" color="PRIMARY" fill="SOLID" shape="PILL" decoration="NONE" shadow="NONE" type="submit" data-aid="CONTACT_SUBMIT_BUTTON_REND" data-tccl="ux2.contact.submit_form.click,click" state="default" data-typography="ButtonAlpha" style={{ fontSize: '16px', width: 'auto', textShadow: 'none', fontWeight: 700, backgroundColor: 'rgb(58, 195, 198)', color: 'rgb(0, 0, 0)', borderRadius: '48px', minHeight: '56px', paddingTop: '8px', paddingRight: '40px', paddingLeft: '40px', textDecoration: 'none solid rgb(0, 0, 0)', alignItems: 'center', display: 'inline-flex', borderStyle: 'none', cursor: 'pointer', transition: 'all 0.3s ease 0s', textTransform: 'uppercase', letterSpacing: '3.424px', paddingBottom: '8px', position: 'relative', overflowWrap: 'break-word', textAlign: 'center', justifyContent: 'center', maxWidth: '100%', fontFamily: 'Lusitana, Georgia, serif', appearance: 'button', overflow: 'visible', margin: '0px', boxSizing: 'border-box' }}>Send Verification</button></div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-ux="Block" style={{ fontSize: '18px', paddingTop: '8px', paddingBottom: '16px', textAlign: 'center', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                                            <p data-ux="DetailsMinor" data-typography="DetailsBeta" style={{ fontSize: '14px', color: 'rgb(89, 89, 89)', lineHeight: '21px', fontWeight: 400, overflowWrap: 'break-word', marginBottom: '0px', marginTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>Este sitio está protegido por reCAPTCHA y aplican las <a rel="noopener" role="link" aria-haspopup="true" data-ux="Link" target="_blank" href="https://policies.google.com/privacy" data-typography="LinkAlpha" style={{ fontSize: '14px', fontWeight: 400, color: 'rgb(38, 131, 133)', display: 'inline', textTransform: 'none', letterSpacing: 'normal', textDecoration: 'none solid rgb(38, 131, 133)', cursor: 'pointer', overflowWrap: 'break-word', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box' }}>Política de privacidad</a> y los <a rel="noopener" role="link" aria-haspopup="true" data-ux="Link" target="_blank" href="https://policies.google.com/terms" data-typography="LinkAlpha" style={{ fontSize: '14px', fontWeight: 400, color: 'rgb(38, 131, 133)', display: 'inline', textTransform: 'none', letterSpacing: 'normal', textDecoration: 'none solid rgb(38, 131, 133)', cursor: 'pointer', overflowWrap: 'break-word', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box' }}>Términos de servicio</a> de Google.</p>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span></div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div style={{ boxSizing: 'border-box' }}>
                                <div data-ux="Widget" role="contentinfo" style={{ boxSizing: 'border-box' }}>
                                    <div style={{ boxSizing: 'border-box' }}>
                                        <section data-ux="Section" style={{ fontSize: '18px', paddingBottom: '40px', paddingTop: '40px', fontFamily: 'Lusitana, Georgia, serif', backgroundColor: 'rgb(255, 255, 255)', textTransform: 'none', letterSpacing: 'normal', display: 'block', boxSizing: 'border-box' }}>
                                            <div data-ux="Container" style={{ width: '1280px', fontSize: '18px', maxWidth: '100%', paddingRight: '24px', paddingLeft: '24px', marginRight: 'auto', marginLeft: 'auto', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                <div data-ux="Layout" style={{ fontSize: '18px', textAlign: 'center', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                    <div data-ux="Container" style={{ width: '1280px', fontSize: '18px', paddingBottom: '24px', maxWidth: '100%', paddingRight: '24px', paddingLeft: '24px', marginRight: 'auto', marginLeft: 'auto', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                                        <p data-ux="FooterText" data-aid="FOOTER_BUSINESS_NAME_RENDERED" data-typography="DetailsGamma" style={{ fontSize: '14px', marginBottom: '16px', letterSpacing: '1.162px', lineHeight: '21px', fontWeight: 700, color: 'rgb(27, 27, 27)', textTransform: 'uppercase', marginTop: '0px', overflowWrap: 'break-word', fontFamily: 'Lusitana, Georgia, serif', boxSizing: 'border-box' }}>DigiQ-AI</p>
                                                    </div>
                                                    <div data-ux="Grid" style={{ fontSize: '18px', flexWrap: 'wrap', flexDirection: 'row', boxSizing: 'border-box', marginRight: '0px', marginLeft: '0px', marginTop: '0px', textAlign: 'center', alignItems: 'center', display: 'flex', marginBottom: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal' }}>
                                                        <div data-ux="GridCell" style={{ fontSize: '18px', maxWidth: 'none', flexBasis: '0%', flexShrink: 1, flexGrow: 1, boxSizing: 'border-box', paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px', paddingTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal' }}>
                                                            <div data-ux="FooterDetails" data-aid="FOOTER_COPYRIGHT_RENDERED" data-typography="DetailsGamma" style={{ fontSize: '14px', textAlign: 'left', letterSpacing: '1.162px', color: 'rgb(89, 89, 89)', lineHeight: '21px', fontWeight: 400, textTransform: 'uppercase', marginTop: '0px', overflowWrap: 'break-word', marginBottom: '0px', fontFamily: 'Lusitana, Georgia, serif', boxSizing: 'border-box' }}>
                                                                <p style={{ margin: '0box-sizing:border-box' }}><span style={{ boxSizing: 'border-box' }}>Copyright © 2023 DigiQ-AI - Todos los derechos reservados.</span></p>
                                                            </div>
                                                        </div>
                                                        <div data-ux="GridCell" style={{ fontSize: '18px', maxWidth: 'none', flexBasis: '0%', flexShrink: 1, flexGrow: 1, boxSizing: 'border-box', paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px', paddingTop: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal' }}>
                                                            <p data-ux="FooterDetails" data-aid="FOOTER_POWERED_BY_RENDERED" data-typography="DetailsGamma" style={{ fontSize: '14px', marginTop: '0px', textAlign: 'right', letterSpacing: '1.162px', color: 'rgb(89, 89, 89)', lineHeight: '21px', fontWeight: 400, textTransform: 'uppercase', overflowWrap: 'break-word', marginBottom: '0px', fontFamily: 'Lusitana, Georgia, serif', boxSizing: 'border-box' }}><span style={{ boxSizing: 'border-box' }}>Powered by LinQex.com</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div style={{ boxSizing: 'border-box' }}>
                                <div data-ux="Group" data-aid="FOOTER_COOKIE_BANNER_RENDERED" style={{ fontSize: '18px', marginLeft: '24px', marginBottom: '24px', marginRight: '24px', marginTop: '24px', borderRadius: '7px', maxHeight: '500px', width: '400px', bottom: '-500px', contain: 'content', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 6px 0px', transition: 'all 1s ease-in 0s', overflowY: 'auto', height: 'auto', zIndex: 10000, right: '0px', position: 'fixed', backgroundColor: 'rgb(58, 195, 198)', textAlign: 'left', paddingBottom: '24px', paddingTop: '24px', paddingRight: '24px', paddingLeft: '24px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                    <h4 role="heading" aria-level="4" data-ux="Heading" data-aid="FOOTER_COOKIE_TITLE_RENDERED" data-typography="HeadingDelta" style={{ fontSize: '24px', color: 'rgb(0, 0, 0)', paddingBottom: '8px', lineHeight: '30px', letterSpacing: '1.08px', fontWeight: 400, marginRight: '0px', marginLeft: '0px', marginTop: '0px', overflowWrap: 'break-word', marginBottom: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', boxSizing: 'border-box' }}>Este sitio web utiliza cookies</h4>
                                    <div data-ux="Text" data-aid="FOOTER_COOKIE_MESSAGE_RENDERED" data-typography="BodyAlpha" style={{ fontSize: '18px', maxHeight: '200px', color: 'rgb(29, 33, 33)', overflowY: 'auto', lineHeight: '27px', fontWeight: 400, marginTop: '0px', overflowWrap: 'break-word', marginBottom: '0px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                        <p style={{ margin: '0box-sizing:border-box' }}><span style={{ boxSizing: 'border-box' }}>Usamos cookies para analizar el tráfico del sitio web y optimizar tu experiencia en el sitio. Al aceptar nuestro uso de cookies, tus datos se agruparán con los datos de todos los demás usuarios.</span></p>
                                    </div>
                                    <div data-ux="Block" style={{ fontSize: '18px', justifyContent: 'space-between', display: 'flex', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><a data-ux-btn="primary" data-ux="ButtonPrimary" color="PRIMARY" fill="SOLID" shape="PILL" decoration="NONE" shadow="NONE" href="" data-aid="FOOTER_COOKIE_CLOSE_RENDERED" data-typography="ButtonAlpha" data-tccl="ux2.COOKIE_BANNER.cookie1.Group.Default.Button.Primary.266564.click,click" style={{ fontSize: '14px', width: 'auto', backgroundColor: 'rgb(22, 22, 22)', color: 'rgb(219, 219, 219)', minHeight: '40px', paddingBottom: '4px', paddingTop: '4px', flexBasis: '50%', wordBreak: 'break-word', marginBottom: '4px', marginTop: '24px', flexDirection: 'column', textShadow: 'none', fontWeight: 700, borderRadius: '48px', borderStyle: 'none', transition: 'all 0.3s ease 0s', letterSpacing: '2.996px', flexGrow: 1, textTransform: 'uppercase', cursor: 'pointer', overflowWrap: 'break-word', textDecoration: 'none solid rgb(219, 219, 219)', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', maxWidth: '100%', paddingRight: '24px', paddingLeft: '24px', position: 'relative', fontFamily: 'Lusitana, Georgia, serif', boxSizing: 'border-box' }}>Aceptar</a></div>
                                </div>
                            </div>
                            <div style={{ boxSizing: 'border-box' }}>
                                <div data-ux="Element" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}><span data-ux="Element" style={{ fontSize: '18px', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                    <div data-ux="Block" style={{ fontSize: '18px', zIndex: 9999, height: '65px', width: '65px', bottom: '24px', right: '24px', position: 'fixed', fontFamily: 'Lusitana, Georgia, serif', textTransform: 'none', letterSpacing: 'normal', boxSizing: 'border-box' }}>
                                        <div style={{ boxSizing: 'border-box' }}>
                                            <div style={{ boxSizing: 'border-box' }}></div>
                                        </div>
                                    </div>
                                </span></div>
                            </div>
                            <div style={{ boxSizing: 'border-box' }}></div>
                        </div>
                    </div>
                </div>
                <div style={{ boxSizing: 'border-box' }}>
                    <div data-style="bottomright" style={{ width: '256px', height: '60px', display: 'block', transition: 'right 0.3s ease 0s', position: 'fixed', bottom: '14px', right: '-186px', boxShadow: 'rgb(128, 128, 128) 0px 0px 5px 0px', borderRadius: '2px', overflow: 'hidden', visibility: 'hidden', boxSizing: 'border-box' }} hidden="true">
                        <div style={{ boxSizing: 'border-box' }}><iframe title="reCAPTCHA" width="256" height="60" role="presentation" name="a-3xm48s4cp3sm" frameBorder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LfjspgUAAAAABsbjG9id6qXQKZkqb6_Hpce6ui_&amp;co=aHR0cHM6Ly9kaWdpcS5haTo0NDM.&amp;hl=es&amp;v=MydHw_zggsxIJuhSbyOmPv5R&amp;size=invisible&amp;cb=mkas4yk87lkl" style={{ boxSizing: 'border-box' }}></iframe></div>
                        <div style={{ boxSizing: 'border-box' }}></div><textarea name="g-recaptcha-response" style={{ width: '250px', height: '40px', border: '0.8px solid rgb(193, 193, 193)', margin: '10px 25px', padding: '0px', resize: 'none', display: 'none', boxSizing: 'border-box' }}></textarea>
                    </div><iframe style={{ display: 'none', boxSizing: 'border-box' }}></iframe>
                </div>
                <div style={{ boxSizing: 'border-box' }}>
                    <div style={{ font: '13px / 17px Nunito, Helvetica, Arial, sans-serif', display: 'block', boxSizing: 'border-box' }}><audio preload="none" style={{ boxSizing: 'border-box' }}>
                        <source src="https://cdn.reamaze.com/audio/snapup.mp3" type="audio/mpeg" style={{ boxSizing: 'content-box' }} />
                        <source src="https://cdn.reamaze.com/audio/snapup.ogg" type="audio/ogg" style={{ boxSizing: 'content-box' }} />
                    </audio><span style={{ boxSizing: 'border-box' }}>
                            <div aria-label="Chat widget toggle" tabIndex="0" role="button" style={{ position: 'fixed', height: '58px', cursor: 'pointer', opacity: '0.95', zIndex: 2147483647, background: 'rgb(58, 195, 198) none repeat scroll 0% 0% / auto padding-box border-box', borderRadius: '58px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px 0px', perspective: '1000px', backfaceVisibility: 'hidden', transition: 'all 0.2s ease-in-out 0s', bottom: '22px', right: '22px', boxSizing: 'border-box' }}><span style={{ display: 'block', overflow: 'hidden', width: '58px', height: '58px', borderRadius: '58px', boxSizing: 'border-box' }}><svg width="58" height="58" style={{ width: '58px', height: '58px', boxSizing: 'border-box' }}>
                                <g transform="scale(1.6111111111111112) translate(2, 2)" style={{ boxSizing: 'border-box' }}>
                                    <g transform="scale(0.7) translate(8, 5.625)" style={{ boxSizing: 'border-box' }}>
                                        <path fill="none" stroke="rgba(255, 255, 255, 1)" stroke-width="3" d="M22.34 3.33008H9.66008C6.7164 3.33008 4.33008 5.7164 4.33008 8.66008V29.0353C4.33008 29.5789 4.9433 29.8962 5.38705 29.5823L11.2917 25.4046C11.9673 24.9267 12.7745 24.67 13.602 24.67H22.34C25.2837 24.67 27.67 22.2837 27.67 19.34V8.66008C27.67 5.7164 25.2837 3.33008 22.34 3.33008Z" style={{ boxSizing: 'border-box' }}></path>
                                    </g>
                                </g>
                            </svg></span></div>
                        </span></div>
                </div><iframe style={{ width: '0px !important', height: '0px', display: 'none', boxSizing: 'border-box' }}></iframe>
            </body>
        </>
    );
}

export default VerificationForm;