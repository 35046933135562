import React from "react";
import Meta from "../components/Meta";
import DashboardSection2 from "../components/DashboardSection2";
import DashboardSection from "../components/DashboardSection";
import { requireAuth } from "../util/auth";
import FirstSection from "../components/HomePage/FirstSection";

function HomePage(props) {
  return (
    <>
      <FirstSection></FirstSection>
    </>
  );
}

export default HomePage;
