import React from "react";
import Meta from "./../components/Meta";
import ContentCardsSection from "./../components/ContentCardsSection";

function ContentPage(props) {
  return (
    <>
      <Meta title="Content" />
      <ContentCardsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Featured Content"
        subtitle=""
      />
    </>
  );
}

export default ContentPage;
