import React from "react";
import Meta from "./../components/Meta";
import FeaturesSection2 from "./../components/FeaturesSection2";
import FeaturesSection from "./../components/FeaturesSection";
import FeaturesSection3 from "./../components/FeaturesSection3";
import FeaturesSection4 from "./../components/FeaturesSection4";

function FeaturesPage(props) {
  return (
    <>
      <Meta title="Features" />
      <FeaturesSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      {/* <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
      />
      <FeaturesSection3
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
      />
      <FeaturesSection4
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        image="https://uploads.divjoy.com/undraw-chatting_2yvo.svg"
      /> */}
    </>
  );
}

export default FeaturesPage;
