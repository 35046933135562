import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useAuth } from "./../util/auth";
import "./PricingSection.scss";

function PricingSection(props) {
  const auth = useAuth();

  const plans = [
    {
      id: "starter",
      name: "Starter",
      price: "10",
      perks: [
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
        "Integer molestie lorem at massa",
      ],
    },
    {
      id: "pro",
      name: "Pro",
      price: "20",
      perks: [
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
        "Integer molestie lorem at massa",
        "Faucibus porta lacus fringilla vel",
        "Aenean sit amet erat nunc",
      ],
    },
    {
      id: "business",
      name: "Business",
      price: "50",
      perks: [
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
        "Integer molestie lorem at massa",
        "Faucibus porta lacus fringilla vel",
        "Aenean sit amet erat nunc",
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
      ],
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {plans.map((plan, index) => (
            <Col
              md={12}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card className="w-100">
                <Card.Body className="d-flex flex-column p-4">
                  <h5 className="fw-bold mb-4">{plan.name}</h5>
                  <h1 className="fw-bold mb-3">
                    ${plan.price}
                    <small className="PricingSection__period">/mo</small>
                  </h1>

                  {plan.description && (
                    <Card.Text className="mb-4">{plan.description}</Card.Text>
                  )}

                  {plan.perks && (
                    <Card.Text as="span" className="mt-2 mb-3">
                      <ul className="list-unstyled">
                        {plan.perks.map((perk, index) => (
                          <li className="py-1" key={index}>
                            <i className="fas fa-check text-success me-3" />
                            {perk}
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                  )}

                  <LinkContainer
                    to={
                      // auth.user ? 
                      (`/purchase/${plan.id}`)
                      // : 
                      // (`/auth/signup?next=/purchase/${plan.id}`)
                    }
                  >
                    <Button variant="primary" size="lg" className="mt-auto">
                      Choose
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default PricingSection;
