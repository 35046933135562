import React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

function Announcement(props) {
  return (
    <Alert variant={props.variant} className="border-0 rounded-0 text-center">
      {props.text}
      <LinkContainer to={props.buttonPath}>
        <Button variant={props.buttonColor} size="md" className="ms-3">
          {props.buttonText}
        </Button>
      </LinkContainer>
    </Alert>
  );
}

export default Announcement;
