import React from "react";
import Meta from "./../components/Meta";
import Footer2 from "./../components/Footer2";
import Footer from "./../components/Footer";

function FooterPage(props) {
  return (
    <>
      <Meta title="Footer" />
      <Footer2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        description="A short description of what you do here"
        copyright={`© ${new Date().getFullYear()} Company`}
        logo="https://uploads.divjoy.com/logo.svg"
      />
      {/* <Footer
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} Company`}
        logo="https://uploads.divjoy.com/logo.svg"
      /> */}
    </>
  );
}

export default FooterPage;
