import React from "react";
import Form from "react-bootstrap/Form";

const FormField = React.forwardRef((props, ref) => {
  const { error, type, ...inputProps } = props;

  return (
    <>
      {props.label && <Form.Label>{props.label}</Form.Label>}

      <Form.Control
        as={type === "textarea" ? "textarea" : "input"}
        type={type === "textarea" ? undefined : type}
        isInvalid={error ? true : undefined}
        ref={ref}
        {...inputProps}
      />

      {error && (
        <Form.Control.Feedback type="invalid" className="text-start">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
});

export default FormField;
