import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import Avatar from "./Avatar";
import "./UsersSection.scss";

function UsersSection(props) {
  const items = [
    {
      headerImage:
        "https://images.unsplash.com/photo-1492138623748-a1b1f758a762?auto=format&fit=crop&w=800&h=600&q=80 ",
      avatarImage: "https://uploads.divjoy.com/pravatar-150x-68.jpeg",
      name: "John Smith",
      username: "johnsmith",
    },
    {
      headerImage:
        "https://images.unsplash.com/photo-1531915911824-79a6471b9827?auto=format&fit=crop&w=800&h=600&q=80",
      avatarImage: "https://uploads.divjoy.com/pravatar-150x-35.jpeg",
      name: "Lisa Zinn",
      username: "lisazinn",
    },
    {
      headerImage:
        "https://images.unsplash.com/photo-1525268771113-32d9e9021a97?auto=format&fit=crop&w=800&h=600&q=80",
      avatarImage: "https://uploads.divjoy.com/pravatar-150x-16.jpeg",
      name: "Diana Low",
      username: "dianalow",
    },
    {
      headerImage:
        "https://images.unsplash.com/photo-1562065540-efa93744ed71?auto=format&fit=crop&w=800&h=600&q=80",
      avatarImage: "https://i.pravatar.cc/150?img=7",
      name: "Shawn David",
      username: "shawndavid",
    },
    {
      headerImage:
        "https://images.unsplash.com/photo-1561454260-8559bd155736?auto=format&fit=crop&w=800&h=600&q=80",
      avatarImage: "https://i.pravatar.cc/150?img=8",
      name: "Ian Gold",
      username: "iangold",
    },
    {
      headerImage:
        "https://images.unsplash.com/photo-1561571994-3c61c554181a?auto=format&fit=crop&w=800&h=600&q=80",
      avatarImage: "https://i.pravatar.cc/150?img=10",
      name: "Ali Pine",
      username: "alipine",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} md={6} lg={4} className="py-3" key={index}>
              <Card>
                <AspectRatio ratio={4 / 1}>
                  <Image src={item.headerImage} fluid={true} />
                </AspectRatio>
                <Card.Body className="d-flex flex-column text-center align-items-center p-4">
                  <div className="UsersSection__avatar-wrapper">
                    <Avatar
                      src={item.avatarImage}
                      alt={item.name}
                      size="96px"
                    />
                  </div>
                  <h6 className="fw-bold mb-0 mt-3">{item.name}</h6>
                  <small className="text-muted">@{item.username}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default UsersSection;
