import React from "react";
import Meta from "./../components/Meta";
import TeamBiosSection from "./../components/TeamBiosSection";
import TeamBiosSection2 from "./../components/TeamBiosSection2";
import TeamBiosSection3 from "./../components/TeamBiosSection3";
import TeamBiosSection4 from "./../components/TeamBiosSection4";

function TeamPage(props) {
  return (
    <>
      <Meta title="Team" />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      {/* <TeamBiosSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <TeamBiosSection3
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <TeamBiosSection4
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      /> */}
    </>
  );
}

export default TeamPage;
