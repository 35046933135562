import { useEffect } from "react";
import { useRouter } from "./router";
import analyticsInit from "analytics";
import googleAnalyticsPlugin from "@analytics/google-analytics";

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = analyticsInit({
  debug: process.env.NODE_ENV !== "production",
  plugins: [
    googleAnalyticsPlugin({
      measurementIds: process.env.REACT_APP_GA_MEASUREMENT_ID,
    }),
  ],
});

// Track pageview on router change
export function Analytics() {
  const router = useRouter();

  useEffect(() => {
    analytics.page();
  }, [router.location]);

  return null;
}

export default analytics;
