import React from "react";
import Meta from "./../components/Meta";
import PricingSection2 from "./../components/PricingSection2";

function PricingLandingPage(props) {
  return (
    <>
      <Meta title="Pricing Landing" />
      <PricingSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Choose the plan that makes sense for you. All plans include a 14-day free trial."
      />
    </>
  );
}

export default PricingLandingPage;
